<template>
  <div class="container">
    <div class="head">
      <img src="../../assets/bg_new_guide_video_nvip_area.webp" width="200" alt="">
    </div>
    <div class="center">
      <div style="font-weight: 900;font-size: 60px;" class="animate__animated animate__zoomInDown">QrLogin</div>
      <div class="animate__animated animate__lightSpeedInLeft" style="font-weight: 600;font-size: 30px;margin-top: 20px;--animate-duration:0.5s">扫码系统</div>
      <div style="margin-top: 40px">
        <el-row style="margin-bottom: 20px;" class="animate__animated animate__bounceInUp">
          <button class="button" @click="login" style="margin: 0 15px"><span>登录系统</span></button>
          <button class="button" @click="register" style="margin: 0 15px"><span>立即注册</span></button>
        </el-row>
      </div>

<!--      <el-row type="flex" justify="center" style="margin-top: 40px">-->
<!--        <el-col :xs="20" :sm="18" :md="15" :lg="10" :xl="8">-->
<!--          <el-collapse v-model="activeNames" accordion>-->
<!--            <el-collapse-item title="系统测试账号和密码" name="1">-->
<!--              <div>网盘测试账号和密码：test_wp</div>-->
<!--              <div>文库测试账号和密码：test_wk</div>-->
<!--              <div>测试账号仅供测试，账号每天都有人登录。</div>-->
<!--            </el-collapse-item>-->
<!--            <el-collapse-item title="购买系统或只租用 ？" name="2">-->
<!--              <div>联系QQ：3367964245</div>-->
<!--              <div>请备注来意，否则不会通过好友请求</div>-->
<!--            </el-collapse-item>-->
<!--            <el-collapse-item title="需要个性化功能定制 ？" name="3">-->
<!--              <div>联系QQ：3367964245</div>-->
<!--              <div>请备注来意，否则不会通过好友请求</div>-->
<!--            </el-collapse-item>-->
<!--            <el-collapse-item title="不会使用？预约演示 ？" name="4">-->
<!--              <div>联系QQ：3367964245</div>-->
<!--              <div>系统界面简洁直观，操作设计简单易用，大部分用户能够快速上手。</div>-->
<!--              <div>若你还是不懂请预约演示，预约演示收取服务费，为你安排远程会议，进行详细讲解</div>-->
<!--            </el-collapse-item>-->
<!--          </el-collapse>-->
<!--        </el-col>-->
<!--      </el-row>-->

      <div class="footer">
      </div>
    </div>
  </div>
</template>

<script>
import 'animate.css';

export default {
  data() {
    return {
      activeNames: ['1'],
    }
  },
  mounted() {
    document.body.style.overflowX = 'hidden';
    document.body.style.overflowY = 'hidden';
  },
  beforeDestroy() {
    // 恢复 body 的样式
    document.body.style.overflowX = '';
    document.body.style.overflowY = '';
  },
  created() {
  },
  methods: {
    register() {
      this.$router.push('/register');
    },
    login() {
      let user = localStorage.getItem("account");
      if (user !== "admin") {
        this.$router.push('/login');
      } else {
        this.$router.push('/admin');
      }
    }
  }
}
</script>

<style scoped lang="less">

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
}

.button {
  display: inline-block;
  border-radius: 7px;
  border: none;
  background: #1875FF;
  color: white;
  font-family: inherit;
  text-align: center;
  font-size: 13px;
  box-shadow: 0px 14px 56px -11px #1875FF;
  width: 10em;
  padding: 1em;
  transition: all 0.4s;
  cursor: pointer;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.4s;
}

.button span:after {
  content: 'Go !';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.7s;
}

.button:hover span {
  padding-right: 2.55em;
}

.button:hover span:after {
  opacity: 4;
  right: 0;
}

.container {
  margin: 0 auto;
  text-align: center;

  .center {
    margin-top: 20px;
  }

  .head {
    background-image: url("@/assets/svg/bbburst.svg");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100%;
  }
}
</style>