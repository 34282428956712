import axios from "axios";
import router from '../router';

const request = axios.create({
    timeout: 10000, // 请求超时时间
});

// 添加请求拦截器
request.interceptors.request.use(
    async (config) => {
        try {
            const token = await localStorage.getItem("token");
            if (token) {
                config.headers.Authorization = token;
            }
            return config;
        }catch (error) {
            // 如果获取令牌失败，拒绝请求
            return Promise.reject(error);
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 添加响应拦截器
request.interceptors.response.use(
    async (response) => {
        try {
            if (response.data.error === 302) {
                await router.push(response.data.url);
                console.log("重定向了");
                return Promise.reject(new Error('登录状态失效，请先登录')); // 阻止继续处理响应
            }
        } catch (e) {
            console.log("重定向失败：" + e);
        }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);


export default request;
